import React from "react"
import { HeaderVariants } from "../../components/header"
import SEO from "../../components/seo"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import classes from "./post.module.css"
import { Link } from "gatsby"
import LeftIcon from "../../images/arrow-left.inline.svg"
import FacebookIcon from "../../images/facebook.inline.svg"
import TwitterIcon from "../../images/twitter.inline.svg"

const Post = props => (
  <HomePageLayout headerVariant={HeaderVariants.dark} pageTitle="Blog">
    <SEO title="Blog" />
    <div className={classes.header}>
      <img
        src="https://picsum.photos/1360/700"
        alt="Post Image"
        className={classes.headerImage}
      />
      <div className={classes.headerContent}>
        <p className={classes.headerContentText}>
          What skills do you need as a UI/UX designer
        </p>
      </div>
    </div>
    <div className={classes.container}>
      {/*<Link to="/blog" className={classes.link}>*/}
      {/*  <LeftIcon fill="#333" width="15px" />*/}
      {/*  <span className={classes.linkText}>Back</span>*/}
      {/*</Link>*/}
      <div className={classes.postContentGrid}>
        <div>
          <p className={classes.sharePostHeader}>Share Post</p>
          <a className={classes.sharePostLink} href="#">
            <TwitterIcon height="18px" />
            <span className={classes.sharePostLinkText}>Tweet Post</span>
          </a>
          <a className={classes.sharePostLink} href="#">
            <FacebookIcon height="18px" />
            <span className={classes.sharePostLinkText}>Facebook Post</span>
          </a>
        </div>
        <div className={classes.postContentWrapper}>
          <h1 className={classes.postHeader}>Becoming a developer</h1>
          <div className={classes.postDetails}>
            <img
              src="https://randomuser.me/api/portraits/women/88.jpg"
              alt="Author Avatar"
              className={classes.postDetailsImage}
            />
            <div className={classes.postDetailsText}>
              <p className={classes.postDetailsAuthor}>Samuel Tola</p>
              <p className={classes.postDetailsDate}>Nov 4, 2019</p>
            </div>
          </div>
          <div className={classes.postContent}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi
              aperiam aut, deleniti dolorem earum in magni natus necessitatibus,
              nulla omnis rem repellendus reprehenderit sint, tempore temporibus
              tenetur veniam vero voluptatum.Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Animi aperiam aut, deleniti dolorem
              earum in magni natus necessitatibus, nulla omnis rem repellendus
              reprehenderit sint, tempore temporibus tenetur veniam vero
              voluptatum.Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Animi aperiam aut, deleniti dolorem earum in magni natus
              necessitatibus, nulla omnis rem repellendus reprehenderit sint,
              tempore temporibus tenetur veniam vero voluptatum.Lorem ipsum
              dolor sit amet, consectetur adipisicing elit. Animi aperiam aut,
              deleniti dolorem earum in magni natus necessitatibus, nulla omnis
              rem repellendus reprehenderit sint, tempore temporibus tenetur
              veniam vero voluptatum.Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Animi aperiam aut, deleniti dolorem earum in
              magni natus necessitatibus, nulla omnis rem repellendus
              reprehenderit sint, tempore temporibus tenetur veniam vero
              voluptatum.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi
              aperiam aut, deleniti dolorem earum in magni natus necessitatibus,
              nulla omnis rem repellendus reprehenderit sint, tempore temporibus
              tenetur veniam vero voluptatum.Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Animi aperiam aut, deleniti dolorem
              earum in magni natus necessitatibus, nulla omnis rem repellendus
              reprehenderit sint, tempore temporibus tenetur veniam vero
              voluptatum.Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Animi aperiam aut, deleniti dolorem earum in magni natus
              necessitatibus, nulla omnis rem repellendus reprehenderit sint,
              tempore temporibus tenetur veniam vero voluptatum.Lorem ipsum
              dolor sit amet, consectetur adipisicing elit. Animi aperiam aut,
              deleniti dolorem earum in magni natus necessitatibus, nulla omnis
              rem repellendus reprehenderit sint, tempore temporibus tenetur
              veniam vero voluptatum.Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Animi aperiam aut, deleniti dolorem earum in
              magni natus necessitatibus, nulla omnis rem repellendus
              reprehenderit sint, tempore temporibus tenetur veniam vero
              voluptatum.
            </p>
          </div>
        </div>
      </div>
    </div>
  </HomePageLayout>
)

export default Post
